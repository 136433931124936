body {
   /* background: url('./freshwash305.PNG');*/
    background-size: cover;
    background-color: #4A8BD4;
}

.service-button {
    display: flex;  
    justify-content: center;  
    align-items: center;
    size: 400px;  
    padding-top: 40px;
}


.blog-cards {
    padding: 30px 10px 30px 10px;
    width: 800px;
}

.carousel {
    width: 80%;
    right: -150px;
}





.footer {
    background-color: #414141;
    width: 100%;
    text-align: left;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 16px;
    padding: 50px;
    margin-top: 50px;
  }
  
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: inline-block;
    vertical-align: top;
  }
  
  
  /* footer left*/
  
  .footer .footer-left {
    width: 33%;
    padding-right: 15px;
  }
  
  .footer .about {
    line-height: 20px;
    color: #ffffff;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
  }
  
  .footer .about span {
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .footer .icons {
    margin-top: 25px;
  }
  
  .footer .icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
  }
  
  
  /* footer center*/
  
  .footer .footer-center {
    width: 30%;
  }
  
  .footer .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
  }
  
  .footer .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
  }
  
  .footer .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
  }
  
  .footer .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
  }
  
  .footer .footer-center p a {
    color: #0099ff;
    text-decoration: none;
  }
  
  
  /* footer right*/
  
  .footer .footer-right {
    width: 35%;
  }
  
  .footer h2 {
    color: #ffffff;
    font-size: 36px;
    font-weight: normal;
    margin: 0;
  }
  
  .footer h2 span {
    color: #0099ff;
  }
  
  .footer .menu {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
  }
  
  .footer .menu a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
  }
  
  .footer .menu a:hover {
    color: #0099ff;
  }
  
  .footer .name {
    color: #0099ff;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }
  
  @media (max-width: 767px) {
    .footer {
      font-size: 14px;
    }
    .footer .footer-left,
    .footer .footer-center,
    .footer .footer-right {
      display: block;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
    }
    .footer .footer-center i {
      margin-left: 0;
    }
  }